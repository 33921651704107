import React from 'react';
import { AiOutlineLink } from 'react-icons/ai';

interface Props {
    text: string;
    externalLink: string;
    border?: boolean;
    className?: string;
}

export default function ExternalButton({ 
    text,
    externalLink,
    border = true,
    className = ''
}: Props) {
  return (
    <a 
        className={`bg-lightest button-text py-2 px-4 rounded inline-flex mr-4 mt-4 ${border ? 'border-theme border-2' : ''} ${className}`}
        rel="noopener noreferrer"
        target="_blank"
        href={externalLink}
    >
        <AiOutlineLink
            size={20}
            className="pr-1 mr-1"
        />
        <span className="text-sm">{text}</span>
    </a>
  );
}