import React from "react"
import ExternalButton from "../components/buttons/ExternalButton"
import Container from "../components/layout/Container"
import Layout from "../components/layout/Layout"
import WideContainer from "../components/layout/WideContainer"
import MetaData from "../components/seo/MetaData"
import OrganizationStructuredData from "../components/seo/OrganizationStructuredData"
import { Animal } from "../models/AnimalModel"

interface Props {
  pageContext: PageContext
  location: any
}

interface PageContext {
  data: Animal
}

const AnimalTemplate: React.FunctionComponent<Props> = ({
  pageContext,
  location,
}) => {
  return (
    <Layout>
      <MetaData
        title={`${pageContext.data.animal_name[0].text} (${pageContext.data.latin_animal_name[0].text}) - National Park Wildlife`}
        description={`Read more about the animal: ${pageContext.data.animal_name[0].text}. Get to know the animal before you visit a national park.`}
        thumbnailUrl={pageContext.data.main_animal_image.url}
        imageAlt={`${pageContext.data.main_animal_image.alt}`}
        pageUrl={location.pathname}
      />
      <OrganizationStructuredData />
      <WideContainer center={true}>
        <div className={`container md:px-2 px-2`}>
          <h1 className="mt-6 md:mt-8 mb-0">
            {pageContext.data.animal_name[0].text}
          </h1>
          <p className="mt-0">{pageContext.data.latin_animal_name[0].text}</p>
        </div>
      </WideContainer>
      <Container center={true} className="mb-2">
        <img
          src={pageContext.data.main_animal_image.url}
          alt={pageContext.data.main_animal_image.alt}
          loading="eager"
        />
        <p className="mt-2 px-6 py-1">{pageContext.data.description[0].text}</p>
      </Container>
      <WideContainer center={true} className="mb-8">
        <ExternalButton
          text="Wikipedia Page"
          externalLink={pageContext.data.wikipedia_article_link.url}
        />
      </WideContainer>
    </Layout>
  )
}

export default AnimalTemplate
